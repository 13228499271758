import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="2027/fulcrum.hm50tt58s" title="Steamboat%203D%20Model%20Walkthrough" width="498"`}</MDXTag>
<MDXTag name="p" components={components}>{`:::md-component FulcrumImageVideo blockType="video" handle="2027/fulcrum.0g354h17x" title="Temple%203D%20Model%20Walkthrough" width="500"`}</MDXTag>
<MDXTag name="p" components={components}>{`Early in the pre-production process, Welles commissioned two set models, one of the boat that carries Marlow and the largely German crew down the river, and the other of Kurtz’s temple in the jungle. Both miniatures—which survive only in the behind-the-scenes photographs in which they appear—were important for the visual design of the film, which Welles and his collaborators planned to depict through use of the most up-to-date special effects. Using 3D modeling software, we have constructed three-dimensional models of these two spaces. Welles and his team made these miniatures to appear life-size through special effects cinematography. Reimagining these miniatures as 3D models allows users to explore these simulations while approximating the first-person subjective experience Welles wanted to create in the film by aligning spectators with Marlow’s visual point of view. Many of the film’s scenes were to have taken place onboard the boat with Marlow hemmed up with the German crew under the thrall of Kurtz--a claustrophobic environment that might have worked metaphorically. The temple scene with which the film was to have climaxed was to have been even more explicitly tied to Welles’s anti-fascism. Indeed, “more than any other in the movie,” Welles insisted that the temple scene “requires a tremendous amount of space, the biggest set possible” in order to resemble fascist spectacles like the Nuremberg Rallies.`}</MDXTag>
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://data.sentiovr.com/spaces/14210/space_1583868933/vtour/tour.html"}}>{`here`}</MDXTag>{` to tour the steamboat yourself. `}</MDXTag>
<MDXTag name="p" components={components}>{`Click `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://data.sentiovr.com/spaces/14210/space_1583869319/vtour/tour.html"}}>{`here`}</MDXTag>{` to tour the temple yourself. `}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/wellesandsteamboat.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/1939_orsonwellesandedwarddonohueexaminingmodelofthetempletouseinhod_1.jpg","alt":null}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"3D Models","key":"3d-vr-models","parentKey":"heart-of-darkness","sortOrder":6};

  